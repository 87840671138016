import {Dropdown, Form} from './';
import React, {useState} from 'react';
import {FormikValues} from "formik/dist/types";


type Props = {
    disabled?: boolean,
    btnLabel: string,
    onFilterUpdate?: (values: FormikValues) => void,
    filters: {type: string, name: string, label: string, options?: {label: string, value: string}[]}[],
    search?: string,
    resetFilter?: () => void,
};

export const Filter: (props: Props) => JSX.Element =
    ({
         disabled,
         btnLabel,
         onFilterUpdate,
         filters,
         search: searchProvided,
         resetFilter
    }) => {

    const [activeFilters, setActiveFilters] = useState(0);

    const handleSubmit = (values: FormikValues, actions: any) => {

        actions.setSubmitting(true);

        if (onFilterUpdate) {
            onFilterUpdate(values);
        }

        let selectedFilters = 0;

        Object.keys(values).forEach(key => {
            if (values[key]) {
                selectedFilters += 1;
            }
        })

        setActiveFilters(selectedFilters);

        actions.setSubmitting(false);
    };

    return (
        <Dropdown
            btnBadge={activeFilters ? activeFilters : 0}
            disabled={disabled}
            btnLabel={btnLabel}
            content={
                <div className="flex flex-col space-y-4 max-h-96 overflow-y-auto lg:p-4">
                    <Form
                        enableReinitialize={true}
                        initValues={filters.reduce(
                            (result: any, currentValue) => {
                                    if (currentValue.name === 'search') {
                                        result[currentValue.name] = searchProvided;
                                        return result;
                                    }
                                    if (!result[currentValue.name]) {
                                        result[currentValue.name] = '';
                                    }
                                    return result
                                }, {}
                            )
                        }
                        className="w-full"
                        cancelBtn={() => {
                            resetFilter?.();
                            setActiveFilters(0);
                        }}
                        resetInitValuesOnCancel={true}
                        cancelBtnLabel="bk.placeholder.filter.reset"
                        validationSchema={{}}
                        onSubmit={handleSubmit}
                        fields={filters.map(filter => ({type: filter.type, name: filter.name, label: filter.label, options: filter.options}))}
                        submitBtnLabel={btnLabel}
                    />
                </div>
            }
        />
    );
};
