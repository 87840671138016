import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Checkbox, Filter, Form, Icon, InputSelect, Loader, Modal, Text, Calendar, Dropdown } from './';
import { api, formatDate, formatNumber, useAppSelector } from '../_library';
import config from "../config";
import * as Yup from 'yup';
import {FormikValues} from "formik/dist/types";

type Props = {
    columns: {
        name: string,
        label: string,
        type?: 'rows' | 'status' | 'role' | 'date' | 'number' | 'money' | 'confirmation' | 'textListByLangs',
        actions?: {
            hideWhen: string,
            hideWhenValue: any,
            label: string,
            onClick: (row: any, columnRow: any) => void
        }[],
        rows?: string[],
        includeIndex?: boolean,
        width?: string,
        icons?: React.ReactNode[],
        withTime?: boolean,
        descriptionField?: string,
        statuses?: {[key: string]: string},
        className?: string,
    }[],
    dataLoader?: {
        apiCall?: {
            path: string,
            rowsNameInResponse: string,
            totalPagesNameInResponse: string,
            totalRecordsNameInResponse: string,
            totalSumNameInResponse?: string,
            dateLimit?: string,
            downloadDataPath?: string,
            downloadedFileName?: string
        }
    },
    rows?: {[key: string]: any}[],
    total?: number,  //total records
    sum?: number, //total sum of one of column, for example sum of all transactions for all pages
    pages?: number,  //total pages
    setPage?: (page: number) => void,
    page?: number,
    limit?: number
    pagination?: boolean,
    title?: string,
    periodSelect?: boolean,
    itemsPerPageSelect?: boolean,
    dateSelect?: boolean,
    exportDataButton?: boolean
    statuses?: {[key: string]: string},
    roles?: {[key: string]: string},
    actions?: {
        onClick: (row: any) => void,
        label: string,
        hideWhen?: string,
        hideWhenValue?: any
    }[],
    renderActionButtons?: (isLoading: boolean) => React.ReactNode[],
    onRowSelected?: (rows: any[], row?: any) => void, //display checkbox on each row and allow to select row or not
    onRowSelectPassDataField?: string,  //only one field from row will be passed to onRowSelected if not passed then whole row will be passed
    isSearchEnabled?: boolean,
    renderActionButtonsForSelectedRows?: (isLoading: boolean) => React.ReactNode[],
    filter?: {
        btnLabel: string,
        fields: {type: string, name: string, label: string, options?: {label: string, value: string}[]}[]
    }
    setPeriod?: (period: string) => void,
    setFilterRef?: (ref: any) => void,
    reloadDataTrigger?: (dataLoader: any) => void
};

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];


export const Table: (props: Props) => JSX.Element =
    ({
         columns,
         dataLoader,
         rows,
         total,
         sum,
         pages,
         setPage: setPageCallback,
         page: pageProvided,
         pagination = true,
         limit,
         title,
         periodSelect,
         itemsPerPageSelect,
         dateSelect,
         exportDataButton,
         actions,
         statuses,
         roles,
         renderActionButtons,
         onRowSelected,
         onRowSelectPassDataField,
         isSearchEnabled,
         renderActionButtonsForSelectedRows,
         filter,
         setPeriod,
         setFilterRef,
         reloadDataTrigger
     }) => {

        const { lang } = useAppSelector(state => state.i18n);
        const { currency } = useAppSelector(state => state.user);
        const [isLoading, setIsLoading] = useState(!rows);
        const [rowsData, setRowsData] = useState((rows ? rows : []));
        const [totalPages, setTotalPages] = useState((pages ? pages : 0));
        const [totalRecords, setTotalRecords] = useState((total ? total : 0));
        const [totalSum, setTotalSum] = useState((sum ? sum : 0));
        const page = useRef((pageProvided ? pageProvided : 1));
        const [selectedRows, setSelectedRows] = useState<number[]>([]);
        const [search, setSearch] = useState('');
        const [dateLimit, setDateLimit] = useState('1y');
        const [itemsPerPage, setItemsPerPage] = useState('');
        const [calendarValue, setCalendarValue] = useState<Value | null>(null);
        const filterDataRef = useRef('');
        const [confirmationModalContent, setConfirmationModalContent] = useState<string | null>(null);
        const [shouldResetCalendar, setShouldResetCalendar] = useState(false);


        const handleSetPage = (pageNumber: number) => {
            if (setPageCallback) {
                setPageCallback(pageNumber);
            }

            page.current = pageNumber;
            loadData();
        };

        const loadData = (filter?: string) => {
            setIsLoading(true);

            if (dataLoader?.apiCall) {
                //NOTE: axios removes empty arrays from get params if passed
                api.get(`${dataLoader.apiCall.path}?page=${page.current}${search ? `&search=${search}` : ''}${filter ? `&filter=${filter}` : ''}${dataLoader.apiCall.dateLimit ? `&dateLimit=${dataLoader.apiCall.dateLimit}` : `&dateLimit=${dateLimit}`}${calendarValue && Array.isArray(calendarValue) ? `&dateFrom=${formatDate(calendarValue[0])}&dateTo=${formatDate(calendarValue[1])}` : ''}${filterDataRef.current ? `&filter=${filterDataRef.current}` : ''}${itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : ''}`)
                    .then(response => {
                        setRowsData(response.data[dataLoader.apiCall!!.rowsNameInResponse]);
                        setTotalPages(response.data[dataLoader.apiCall!!.totalPagesNameInResponse]);
                        setTotalRecords(response.data[dataLoader.apiCall!!.totalRecordsNameInResponse])
                        if (dataLoader.apiCall!!.totalSumNameInResponse !== undefined && response.data[dataLoader.apiCall!!.totalSumNameInResponse] !== undefined) {
                          setTotalSum(response.data[dataLoader.apiCall!!.totalSumNameInResponse])
                        }
                        setIsLoading(false);
                    })
                    .catch()
            }
        };

        const handleDataDownload = () => {
            if (dataLoader?.apiCall) {
                api.get(`${dataLoader.apiCall.downloadDataPath}?${search ? `search=${search}` : ''}${filterDataRef.current ? `${filterDataRef.current}` : ''}${calendarValue && Array.isArray(calendarValue) ? `&dateFrom=${formatDate(calendarValue[0])}&dateTo=${formatDate(calendarValue[1])}` : ''}`)
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');

                    link.href = url;
                    if (dataLoader.apiCall?.downloadedFileName) {
                        link.setAttribute('download', dataLoader.apiCall.downloadedFileName);
                    } else {
                        link.setAttribute('download', 'transactions.csv');
                    }
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                }).catch(res => {});
            }
        };

        const handleFilterSubmit = (values: FormikValues) => {
            let data = '';

            Object.keys(values).forEach((key) => {
                if (key !== 'search') {
                    data += `&${key}=${values[key]}`;
                } else {
                    data += `&search=${values[key]}`;
                }
            });

            if (data !== filterDataRef.current || (values.search !== undefined && values.search !== search)) {
                if (values.search !== undefined && values.search !== search) {
                    setSearch(values.search);
                }

                filterDataRef.current = data;
                setFilterRef?.(filterDataRef);
                loadData(data);
            }
        };

        const resetFilter = () => {
            setSearch('');
        };

        const onCalendarChange = (newValue: Value) => {
            setCalendarValue(newValue);
            setShouldResetCalendar(false);
        };

        const onCalendarReset = () => {
            setCalendarValue(null);
            setShouldResetCalendar(true);
        };

        useEffect(() => {
            if (reloadDataTrigger) {
                reloadDataTrigger(loadData);
            }

            loadData();
        }, []);

        useEffect(() => {
            // if search reset
            if (search === '') {
                loadData('');
            }
        }, [search]);

        useEffect(() => {
            loadData('');
        }, [dateLimit]);

        useEffect(() => {
            if (shouldResetCalendar) {
                loadData('');
            }
        }, [shouldResetCalendar]);

        useEffect(() => {
            loadData('');
        }, [itemsPerPage]);

        const renderHeader = () => (
            <div>
                <div className="lg:flex justify-between min-h-[50px] items-center mb-8 lg:space-y-0 space-y-6">
                    <div>
                        {title ?
                            <Text className="font-bold" twoXL={true} label={title} />
                            : null
                        }
                    </div>
                    <div className="lg:flex lg:space-x-6 space-x-0 lg:space-y-0 space-y-4">
                        {isSearchEnabled ?
                            <div className="flex items-end">
                                <Form
                                    enableReinitialize={true}
                                    initValues={{search}}
                                    validationSchema={{search: Yup.string()}}
                                    noSubmitBtn={true}
                                    onSubmit={() => loadData()}
                                    onChange={({search: searchValue}: {search: string}) => {
                                        setSearch(searchValue);
                                    }}
                                    className="!space-y-0"
                                    fieldsWrapperClassName="!space-y-0"
                                    fields={[
                                        {
                                            name: 'search',
                                            type: 'text',
                                            placeholder: 'bk.table.placeholder.search',
                                            inputClassName: 'h-14 border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none focus:ring-0 focus:ring-transparent focus:border-bk-grey-light !placeholder-bk-black !text-md',
                                            icon: <Icon type="search" />
                                        }
                                    ]}
                                />
                            </div>
                            : null
                        }
                        {itemsPerPageSelect ?
                            <InputSelect
                                disabled={isLoading}
                                notInForm={true}
                                options={[
                                    {name: '10 per page', value: '10'},
                                    {name: '25 per page', value: '25'},
                                    {name: '50 per page', value: '50'},
                                    {name: '100 per page', value: '100'},
                                ]}
                                selected="10"
                                onChange={(value: any) => {setItemsPerPage(value)}}
                                noInput={true}
                            />
                            : null
                        }
                        {exportDataButton ?
                            <Button
                                disabled={isLoading}
                                color="transparent"
                                label="bk.dashboard.last_transactions.btn.download_csv"
                                onClick={() => handleDataDownload()}
                                icon={<Icon type="download" />}
                                smallPaddingOnSides={true}
                                noShadow={true}
                            />
                            : null
                        }
                        {renderActionButtons ?
                            <>{renderActionButtons(isLoading)}</>
                            : null
                        }
                        {filter ?
                            <Filter
                                disabled={isLoading}
                                btnLabel={filter.btnLabel}
                                filters={filter.fields}
                                onFilterUpdate={handleFilterSubmit}
                                resetFilter={resetFilter}
                                search={search}
                            />
                            : null
                        }
                        {periodSelect ?
                            <InputSelect
                                disabled={isLoading}
                                notInForm={true}
                                options={[
                                    {name: '12 months', value: '1y'},
                                    {name: '6 months', value: '6m'},
                                    {name: '3 months', value: '3m'},
                                    {name: '1 month', value: '1m'}
                                ]}
                                selected="1y"
                                onChange={(value: any) => {setDateLimit(value); setPeriod?.(value);}}
                                noInput={true}
                            />
                            : null
                        }
                        {dateSelect ?
                            <>
                                <Dropdown
                                    smallContainer={true}
                                    btnLabel={"bk.table.select_date"}
                                    content={
                                        <>
                                            <div>
                                                <Calendar
                                                    value={calendarValue}
                                                    onChange={onCalendarChange}
                                                />
                                            </div>
                                            <div className="flex justify-between mt-3 space-x-4">
                                                <Button
                                                    extraSmall={true}
                                                    label={'bk.table.select_date'}
                                                    onClick={() => loadData()}
                                                />
                                                <Button
                                                    extraSmall={true}
                                                    secondary={true}
                                                    label={'bk.table.reset_date'}
                                                    onClick={() => onCalendarReset()}
                                                />
                                            </div>
                                        </>
                                    }
                                />
                            </>
                            : null
                        }
                    </div>
                </div>
                {renderActionButtonsForSelectedRows && selectedRows.length > 0 ?
                    <div className="flex space-x-4 items-center mb-4">
                        <Text label="bk.table.actions" />
                        {renderActionButtonsForSelectedRows(isLoading)}
                    </div>
                    : null
                }
            </div>
        );

        if (isLoading) {
            return (
                <div>
                    {renderHeader()}
                    <div className="flex flex-col bg-white">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="overflow-hidden border-gray-200">
                                    <Loader />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return(
            <div>
                {renderHeader()}
                <div className="flex flex-col bg-white">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="overflow-hidden border-gray-200">
                                <table className="w-full lg:table-fixed">
                                    <thead className="">
                                    <tr>
                                        {onRowSelected ?
                                            <th className="w-10">
                                                <div className="flex -mt-5">
                                                    {selectedRows.length === 0 ||
                                                    selectedRows.length === rowsData.length ?
                                                        <Checkbox
                                                            checked={(selectedRows.length === rowsData.length)}
                                                            onChange={() => {
                                                                if (selectedRows.length === rowsData.length) {
                                                                    setSelectedRows([]);
                                                                    if (onRowSelected) {
                                                                        onRowSelected([]);
                                                                    }
                                                                } else {
                                                                    setSelectedRows(rowsData.map((row, idx) => idx))
                                                                    if (onRowSelected) {
                                                                        onRowSelected(rowsData.map((row, idx) => {
                                                                            if (onRowSelectPassDataField) {
                                                                                return row[onRowSelectPassDataField];
                                                                            }

                                                                            return row;
                                                                        }));
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        :
                                                        <div
                                                            onClick={() => {
                                                                setSelectedRows([]);
                                                                if (onRowSelected) {
                                                                    onRowSelected([]);
                                                                }
                                                            }}
                                                            className="w-5 h-5 bg-bk-p rounded flex items-center justify-center cursor-pointer"
                                                        >
                                                            <div className="w-3 h-[3px] bg-white rounded"></div>
                                                        </div>
                                                    }
                                                </div>
                                            </th>
                                            : null
                                        }
                                        {columns.map((column, idx) => (
                                                <th key={idx} scope="col"
                                                    className={'py-3 pb-8 px-2 lg:px-0 text-bk-grey-100 text-left text-sm ' + (column.width ? column.width : '' ) + (column.type === 'textListByLangs' ? ' pl-5' : '')}>
                                                    <FormattedMessage id={column.label} />
                                                </th>
                                            )
                                        )}
                                        {/*{actions &&*/}
                                        {/*    <th scope="col" className={'py-3 text-bk-grey-100 text-left text-sm'}>*/}
                                        {/*        <FormattedMessage id={'Actions'}/>*/}
                                        {/*    </th>*/}
                                        {/*}*/}
                                    </tr>
                                    </thead>
                                    <tbody className="">
                                    {rowsData.map((row, idx) => (
                                        <tr key={idx} className="border-dotted border-t">
                                            {onRowSelected ?
                                                <td className="px-2 lg:px-0">
                                                    <div className="flex -mt-4">
                                                        <Checkbox checked={selectedRows.indexOf(idx) !== -1} onChange={() => {
                                                            const itemIdx = selectedRows.indexOf(idx);
                                                            const newSelectedRows = [...selectedRows];

                                                            if (itemIdx !== -1) {
                                                                newSelectedRows.splice(itemIdx, 1);
                                                            } else {
                                                                newSelectedRows.push(idx);
                                                            }

                                                            setSelectedRows(newSelectedRows);

                                                            if (onRowSelected) {
                                                                onRowSelected(newSelectedRows.map((rowIdx) => {
                                                                    if (onRowSelectPassDataField) {
                                                                        return rowsData[rowIdx][onRowSelectPassDataField];
                                                                    }

                                                                    return rowsData[rowIdx];
                                                                }), (onRowSelectPassDataField ? rowsData[idx][onRowSelectPassDataField] : rowsData[idx]));
                                                            }
                                                        }} />
                                                    </div>
                                                </td>
                                                : null
                                            }
                                            {columns.map((column, columnIdx) => {
                                                    switch (column.type) {
                                                        case 'rows':
                                                            return (
                                                                <td key={idx + '_' + columnIdx} className="pt-4 lg:pb-8 py-3 px-2 lg:px-0">
                                                                    <div>
                                                                        {row[column.name].map((columnRow: any, columnRowIdx: number) => (
                                                                                <div key={idx + '_' + columnRowIdx}
                                                                                     className={'text-md flex break-words ' + (columnRow.gray ? 'text-gray-500' : 'font-medium text-gray-900')}>
                                                                                    {column.includeIndex ?
                                                                                        <>{(columnRowIdx + 1)}.&nbsp;</>
                                                                                        : null
                                                                                    }
                                                                                    {column.rows?.map((columnRowName, columnRowNameIdx) => (
                                                                                            <div key={idx + '_' + columnRowIdx + '_' + columnRowNameIdx}>
                                                                                                {columnRow[columnRowName]}
                                                                                                {column.actions &&
                                                                                                    <>{column.actions.map((action, idx) => {
                                                                                                            if (action.hideWhen && columnRow[action.hideWhen] === action.hideWhenValue) {
                                                                                                                return null;
                                                                                                            }

                                                                                                            return (
                                                                                                                <a key={idx} className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500 hover:underline m-1"
                                                                                                                   onClick={() => action.onClick(row, columnRow)}>
                                                                                                                    <FormattedMessage id={action.label}/>
                                                                                                                </a>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            );
                                                        case 'status':
                                                            return (
                                                                <td key={idx + '_' + columnIdx} className={"pt-4 lg:pb-8 py-3 whitespace-nowrap px-2 lg:px-0 "}>
                                                                    <span className="px-2 inline-flex text-md items-center font-semibold rounded-full bg-gray-100">
                                                                        {column.icons ?
                                                                            <span className="pr-2">{column.icons[row[column.name]]}</span>
                                                                            : null
                                                                        }
                                                                        <span>
                                                                            {column.statuses ?
                                                                                <FormattedMessage id={column.statuses[row[column.name]]} />
                                                                                :
                                                                                statuses ?
                                                                                    <FormattedMessage id={statuses[row[column.name]]} />
                                                                                    : null
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            );
                                                        case 'role':
                                                            return (
                                                                <td key={idx + '_' + columnIdx} className="pt-4 lg:pb-8 py-3 whitespace-nowrap text-md text-gray-500 px-2 lg:px-0">
                                                                    {roles ? <FormattedMessage id={roles[row[column.name]]} /> : null}
                                                                </td>
                                                            );
                                                        case 'date':
                                                            return (
                                                                <td key={idx + '_' + columnIdx} className={"pt-4 lg:pb-8 py-3 whitespace-nowrap px-2 lg:px-0 "}>
                                                                    <div className="text-md font-medium text-gray-900">
                                                                        {formatDate(row[column.name], column.withTime !== undefined ? column.withTime : true)}
                                                                    </div>
                                                                </td>
                                                            );
                                                        case 'number':
                                                            return (
                                                                <td key={idx + '_' + columnIdx} className={"pt-4 lg:pb-8 py-3 whitespace-nowrap px-2 lg:px-0 "}>
                                                                    <div className="text-md font-medium text-gray-900">
                                                                        {formatNumber(row[column.name], true)}
                                                                    </div>
                                                                </td>
                                                            );
                                                        case 'money':
                                                            return (
                                                                <td key={idx + '_' + columnIdx} className={"pt-4 lg:pb-8 py-3 whitespace-nowrap px-2 lg:px-0 "}>
                                                                    <div className={'text-md font-medium ' + (row[column.name] < 0 ? 'text-bk-red' : 'text-bk-green')}>
                                                                        {formatNumber(row[column.name], true)} {currency}
                                                                    </div>
                                                                </td>
                                                            );
                                                        case 'confirmation':
                                                            return (
                                                                <td
                                                                    key={idx + '_' + columnIdx}
                                                                    className={"pt-4 lg:pb-8 py-3 whitespace-nowrap px-2 lg:px-0 "}
                                                                    onClick={() => setConfirmationModalContent(column.descriptionField ? row[column.descriptionField] : null)}
                                                                >
                                                                    {row[column.name] &&
                                                                        <div className="text-sm font-medium text-red-600">
                                                                            <FormattedMessage id="bk.table.column.confirmation.yes" />
                                                                        </div>
                                                                    }
                                                                    {!row[column.name] &&
                                                                        <div className="text-sm font-medium text-green-600">
                                                                            <FormattedMessage id="bk.table.column.confirmation.no" />
                                                                        </div>
                                                                    }
                                                                </td>
                                                            );
                                                        case 'textListByLangs':
                                                          return (
                                                            <td key={idx + '_' + columnIdx} className={(column.className ? column.className + 'pt-4 pb-8 py-3 pl-5 whitespace-nowrap text-md text-gray-900 overflow-hidden' : '')}>
                                                              {Array.isArray(row[column.name]) ?
                                                                row[column.name].map((columnRow: {[key: string]: string}, columnRowIdx: number) => (
                                                                    <div key={idx + '_' + columnIdx + '_' + columnRowIdx}>
                                                                      {columnRow[lang] !== undefined ? columnRow[lang] : ''}
                                                                    </div>
                                                                  )
                                                                ) :
                                                                column.name.indexOf('.') !== -1 ? column.name.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), row) : row[column.name]
                                                              }
                                                            </td>
                                                          );
                                                        default:
                                                            return (
                                                                <td key={idx + '_' + columnIdx} className={(column.className ? column.className + 'pt-4 lg:pb-8 py-3 whitespace-nowrap text-md font-medium text-gray-900 overflow-hidden px-2 lg:px-0' : 'px-2 lg:px-0')}>
                                                                  <p className="break-all">{column.name.indexOf('.') !== -1 ? column.name.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), row) : row[column.name]}</p>
                                                                </td>
                                                            );
                                                    }
                                                }
                                            )}
                                            {actions &&
                                                <td className={"pt-4 pb-8 py-3 whitespace-nowrap text-gray-900 overflow-hidden flex justify-end "}>
                                                    {actions.map((action, idx) => {

                                                        if (action.hideWhen && row[action.hideWhen] === action.hideWhenValue) {
                                                            return null;
                                                        }

                                                        return (
                                                            <Button
                                                                key={idx}
                                                                extraSmall={true}
                                                                secondary={true}
                                                                label={action.label}
                                                                onClick={() => action.onClick(row)}
                                                                className="rounded-full"
                                                            />
                                                        );
                                                    })
                                                    }
                                                </td>
                                            }
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {pagination ?
                      <div className="py-3 pr-4 mt-4">
                        <span className="text-gray-500 text-sm">{totalRecords} <Text
                          label="bk.placeholder.table.total"/></span>
                        {dataLoader?.apiCall?.totalSumNameInResponse ?
                          <span className="text-gray-500 text-sm ml-12">{formatNumber(totalSum, true)} {currency} <Text
                            label="bk.placeholder.table.totalSum"/></span>
                            : null
                        }
                        <TableNavigation setPage={handleSetPage!!} total={totalRecords!!} page={page.current!!}
                                         limit={(limit ? limit : config.itemsPerPage)}
                                         className="float-right"/>
                      </div>
                      : null
                    }
                  <Modal isOpen={Boolean(confirmationModalContent)} toggle={() => setConfirmationModalContent(null)}
                           title="bk.admin.table.modal.confirmation.description"
                           content={confirmationModalContent}
                    />
                </div>
            </div>
        );
    };


type TableNavigationType = {
    total: number,
    page: number,
    limit: number,
    className?: string,
    setPage: (page: number) => void
};

export const TableNavigation: (props: TableNavigationType) => JSX.Element | null =
    ({
         total,
         page,
         limit,
         className,
         setPage
     }) => {

        const lastPage = Math.ceil(total/limit);

        const numberBtns = () => {
            let rows = [];
            let startPage;

            if (lastPage <= 5 || page < 3) {
                startPage = 1;
            } else if (page > lastPage - 3) {
                startPage = lastPage - 4;
            } else {
                startPage = page - 2;
            }

            for (let i = 0; i < Math.min(5, lastPage); i++) {
                let pageNumber = startPage + i;
                rows.push(
                    <NavButton key={pageNumber} page={pageNumber} selected={(page === pageNumber)} label={pageNumber.toString()} setPage={setPage}/>
                );
            }

            return rows;
        };

        if (lastPage < 2) {
            return null;
        }

        return (
            <div className={"flex flex-row space-x-2 " + (className || '')}>
                {page > 1 && (
                    <>
                        <NavButton label="<<" page={1} disabled={page === 1} setPage={setPage} />
                        <NavButton label="<" page={page - 1} disabled={page === 1} setPage={setPage} />
                    </>
                )}
                {numberBtns()}
                {page < lastPage && (
                    <>
                        <NavButton label=">" page={page + 1} disabled={page === lastPage} setPage={setPage} />
                        <NavButton label=">>" page={lastPage} disabled={page === lastPage} setPage={setPage} />
                    </>
                )}
            </div>
        );
    };


type NavButtonType = {
    page: number,
    label: string | number,
    setPage: (page: number) => void,
    disabled?: boolean,
    selected?: boolean
};

export const NavButton: (props: NavButtonType) => JSX.Element =
    ({
         page,
         label,
         setPage,
         disabled,
         selected = false
     }) => (
        <Button
            onClick={() => setPage(page)}
            disabled={disabled}
            extraSmall={true}
            color={selected ? 'plain' : 'grey'}
            noShadow={true}
        >
            {label}
        </Button>
    );
